import { useEffect } from 'react'
import { Column, Icon, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { GIFTCARD_IMAGES, KIND_OF_GIFTCARD } from 'shared/constants'
import styles from './styles.module.css'

import type { IGiftCard } from '../../GiftcardComponent'

export interface ICardProps {
  balance?: string
  cardNumber?: string
  giftCardType?: string
  index?: number
  showDeleteIcon?: boolean
  handleOpenDeleteGCModal?: (card: IGiftCard) => void
}

export const Card = ({
  balance = '',
  cardNumber,
  giftCardType = KIND_OF_GIFTCARD.GIFTCARD,
  index = 0,
  showDeleteIcon = false,
  handleOpenDeleteGCModal
}: ICardProps) => {
  const cardNumberToShow = cardNumber
    ? cardNumber
        .replace(/-/g, '')
        .slice(0, 18)
        .split(/(.{4})/)
        .filter(Boolean)
    : []

  const giftCardTypeParsed = giftCardType.toUpperCase()
  const getGCImage = GIFTCARD_IMAGES[giftCardTypeParsed][index]

  useEffect(() => {
    const cardContainer = document.getElementsByClassName(styles.card_container)
    const cardContainerLength = cardContainer.length
    for (let i = 0; i < cardContainerLength; i++) {
      const cardContainerElement = cardContainer[i] as HTMLElement
      const cardContainerElementImage =
        cardContainerElement.getAttribute('data-img')
      cardContainerElement.style.backgroundImage = `url(${cardContainerElementImage})`
    }
  }, [])

  const handleDeleteGC = () => {
    handleOpenDeleteGCModal && handleOpenDeleteGCModal({ balance, cardNumber })
  }

  return (
    <Column
      borderRadius={getGlobalStyle('--border-radius-lg')}
      className={styles.card_container}
      customHeight='160px'
      data-img={getGCImage}
      id={getBemId('card', 'container')}
      maxHeight='160px'
      maxWidth='260px'
      minWidth='260px'
      overflow='hidden'
    >
      {showDeleteIcon && (
        <Row
          clickable='pointer'
          id={getBemId('card', 'delete-icon')}
          justifyContent='end'
          maxWidth='248px'
          position='absolute'
          top='8.4'
        >
          <Column
            alignItems='center'
            borderRadius={getGlobalStyle('--border-radius-full')}
            className={styles.delete_card}
            clickable='pointer'
            customHeight='39.4px'
            justifyContent='center'
            maxWidth='37.5px'
            onClick={handleDeleteGC}
            position='absolute'
          ></Column>
          <Column
            alignItems='center'
            clickable='pointer'
            customHeight='39.4px'
            justifyContent='center'
            maxWidth='37.5px'
            onClick={handleDeleteGC}
            zIndex={getGlobalStyle('--z-index-10')}
          >
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-neutral-white')}
              height={16.9}
              name='TrashNew'
              width={16.11}
            />
          </Column>
        </Row>
      )}
      <Row
        id={getBemId('card', 'balance')}
        justifyContent='end'
        maxHeight='max-content'
        maxWidth='248px'
        position='absolute'
        top='64'
      >
        <Text
          color='white'
          fontSize='xl'
          fontWeight='medium'
        >
          {balance}
        </Text>
      </Row>
      <Row
        gap={2}
        id={getBemId('card', 'mumber')}
        left='12px'
        maxHeight='max-content'
        maxWidth='max-content'
        position='absolute'
        top='115'
      >
        {isValidArrayWithData(cardNumberToShow) &&
          cardNumberToShow.map((number, index) => {
            return (
              <Text
                color='white'
                fontSize='2xs'
                key={`card-number-${number}-${index}`}
                lineHeight='normal'
              >
                {number}
              </Text>
            )
          })}
      </Row>
    </Column>
  )
}
