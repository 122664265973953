import { useEffect, useState } from 'react'
import {
  Cookies,
  getCurrentDomain,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'

export interface IUseIndexingMembershipData {
  showIndexingMembership: boolean
  showToast: boolean
  handleCloseToast: () => void
}

/**
 * Custom React hook to manage the state and behavior of "indexing membership" in a checkout process.
 *
 * @returns {IUseIndexingMembershipData} An object containing:
 * - `showIndexingMembership` (boolean): Indicates whether the indexing membership is active.
 * - `showToast` (boolean): Indicates whether to show a toast notification about the indexing membership.
 * - `handleCloseToast` (function): Function to close the toast notification and remove the associated cookie.
 *
 * @example
 * import { useIndexingMembership } from './hooks/useIndexingMembership';
 *
 * const MyComponent = () => {
 *   const { showIndexingMembership, showToast, handleCloseToast } = useIndexingMembership();
 *
 *   return (
 *     <div>
 *       {showIndexingMembership && <p>Indexing Membership is active!</p>}
 *       {showToast && (
 *         <div className="toast">
 *           <p>Your indexing membership has changed!</p>
 *           <button onClick={handleCloseToast}>Close</button>
 *         </div>
 *       )}
 *     </div>
 *   );
 * };
 */
export const useIndexingMembership = (): IUseIndexingMembershipData => {
  const domain = getCurrentDomain()

  const [showIndexingMembership, setShowIndexingMembership] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const isIndexingCookie = JSON.parse(
    Cookies.get('indexingMembership') || 'false'
  )

  const { data, isFetching, isLoading } = useCheckoutV2()

  const handleCloseToast = () => {
    setShowToast(false)
    Cookies.remove('indexingMembership', {
      domain,
      path: '/'
    })
  }

  useEffect(() => {
    if (!isLoading && data) {
      setShowIndexingMembership(data?.isIndexingMembership ?? false)
      if (data?.isIndexingMembership) {
        Cookies.set(
          'indexingMembership',
          JSON.stringify({ indexingMembership: data?.isIndexingMembership }),
          {
            domain
          }
        )
      }
    }
  }, [data, isFetching, isLoading])

  // Validate if indexingMembership was true to show a toast
  useEffect(() => {
    if (
      !isLoading &&
      data &&
      isIndexingCookie?.indexingMembership &&
      !data?.isIndexingMembership
    ) {
      setShowToast(true)
    }
  }, [isIndexingCookie?.indexingMembership, data, isLoading, isFetching])

  return {
    showIndexingMembership,
    showToast,
    handleCloseToast
  }
}
