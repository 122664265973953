import { useEffect, useState } from 'react'
import {
  trigger,
  useCards,
  useCheckoutV2,
  useEvents
} from '@smu-chile/pkg-unimarc-hooks'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'
import { ModalGiftcard } from '../ModalGiftcard'
import { SelectGiftcard } from './SelectGiftcard'
import { GiftcardSelected } from './GiftcardSelected'
import { GiftcardNominativa } from './GiftcardNominativa'
import { KIND_OF_GIFTCARD } from 'shared/constants'

export interface IGiftCard {
  balance?: string
  cardNumber?: string
  hasEnoughAmount?: boolean
  isActive?: boolean
  giftcardType?: string
}

interface GiftcardComponentProps {
  showNotificationBubble: boolean
  handleOpenDeleteGCModal: (card: IGiftCard) => void
}

export const GiftcardComponent = ({
  showNotificationBubble,
  handleOpenDeleteGCModal
}: GiftcardComponentProps): React.ReactElement => {
  const [isOpenGiftCardModal, setIsOpenGiftCardModal] = useState(false)
  const [hasGiftCard, setHasGiftCard] = useState(false)
  const [isShowBlockedAlert, setIsShowBlockedAlert] = useState(false)

  const { data: checkout } = useCheckoutV2()
  const { data: dataCards } = useCards({ version: 2 })

  const currentGiftCardActive = checkout?.paymentInfo?.giftCards?.find(
    (giftCard) => {
      return giftCard.isActive
    }
  )
  const shouldShowGiftCardNominative =
    !hasGiftCard && isValidArrayWithData(dataCards?.nominatives)
  const isCurrentGiftCardNominative =
    currentGiftCardActive?.giftcardType?.toUpperCase() ===
    KIND_OF_GIFTCARD.NOMINATIVA
  const hasNominativeGiftCard =
    !isShowBlockedAlert &&
    (shouldShowGiftCardNominative || isCurrentGiftCardNominative)
  const showGiftCardSelected =
    hasGiftCard && !hasNominativeGiftCard && !isShowBlockedAlert
  const showSelectGiftCard = !hasGiftCard && !hasNominativeGiftCard

  const handleToggleGiftCardModal = () => {
    setIsOpenGiftCardModal((prev) => {
      return !prev
    })
    if (showNotificationBubble) {
      trigger({
        eventType: 'toggleToastGiftCardNominative',
        data: { show: 'false' }
      })
    }
  }

  const handleOpenDeleteGCModalAndCloseCarousel = (card: IGiftCard) => {
    handleOpenDeleteGCModal(card)
    handleToggleGiftCardModal()
  }

  useEvents({
    eventType: 'toggleGiftcardBlocked',
    callBack: ({ detail: { show } }) => {
      setIsShowBlockedAlert(show)
    }
  })

  useEvents({
    eventType: 'toggleModalGiftcard',
    callBack: () => {
      handleToggleGiftCardModal()
    }
  })

  useEffect(() => {
    const newValueHasGiftCard = isValidArrayWithData(
      checkout?.paymentInfo?.giftCards
    )
    if (hasGiftCard !== newValueHasGiftCard) {
      setHasGiftCard(newValueHasGiftCard)
    }

    const savedSeconds = localStorage.getItem('countdownSeconds')
    if (savedSeconds) {
      const parsedSeconds = parseInt(savedSeconds, 10)
      if (parsedSeconds > 0) {
        setIsShowBlockedAlert(true)
      }
    }
  }, [checkout])

  return (
    <>
      {isOpenGiftCardModal && (
        <ModalGiftcard
          handleOpenDeleteGCModal={handleOpenDeleteGCModalAndCloseCarousel}
          onClose={handleToggleGiftCardModal}
        />
      )}
      {hasNominativeGiftCard && (
        <GiftcardNominativa
          handleToggleGiftCardModal={handleToggleGiftCardModal}
        />
      )}
      {showGiftCardSelected && (
        <GiftcardSelected
          giftcardData={currentGiftCardActive}
          handleToggleGiftCardModal={handleToggleGiftCardModal}
        />
      )}
      {showSelectGiftCard && (
        <SelectGiftcard handleToggleGiftCardModal={handleToggleGiftCardModal} />
      )}
    </>
  )
}
