import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalUnipayContent } from './ModalUnipayContent'
import { useModalUnipayValidations } from './hooks/useModalUnipayValidations'
import { useCheckoutV2 } from '@smu-chile/pkg-unimarc-hooks'
import { CODE_UNIPAY_WITH_INSTALLMENTS } from 'shared/constants'
import { ModalUnipayTotal } from './ModalUnipayTotal'
import styles from '../styles.module.css'
import { useDataLayers } from 'shared/hooks'
import { useEffect } from 'react'

interface IModalUnipayProps {
  isLoadingPayment: boolean
  handleToggle: () => void
  handleContinue: (props: unknown) => void
}

const TITLE_MODAL = 'Tarjeta Unipay'
const HEIGHT_MODAL = '564px'

export const ModalUnipay = ({
  isLoadingPayment,
  handleToggle,
  handleContinue
}: IModalUnipayProps) => {
  const { data: checkout } = useCheckoutV2()
  const { isMissingValuesForm, ...modalContentProps } =
    useModalUnipayValidations()
  const { pushCheckoutEventCustom } = useDataLayers()

  const findUniPayValues = checkout?.paymentInfo?.availablePaymentMethods?.find(
    (paymentMethod) => {
      return paymentMethod.id === CODE_UNIPAY_WITH_INSTALLMENTS
    }
  )
  const totalValue = findUniPayValues?.price || checkout?.value

  const handleContinueCustom = () => {
    pushCheckoutEventCustom({
      event: 'interacciones_checkout',
      categoria_evento: 'pago_unipay',
      accion_evento: 'activacion',
      etiqueta_evento: 'boton_continuar',
      payment_type: 'Unipay',
      cuotas: modalContentProps?.simulatePaymentData?.quotas?.toString() ?? '1'
    })
    handleContinue({
      rut: modalContentProps.values.rut?.replace(/\./g, ''),
      simulatePaymentData: modalContentProps.simulatePaymentData
    })
  }

  useEffect(() => {
    pushCheckoutEventCustom({
      event: 'interacciones_checkout',
      categoria_evento: 'pago_unipay',
      accion_evento: 'vista',
      etiqueta_evento: 'modal',
      payment_type: 'Unipay'
    })
  }, [])

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='ModalUnipayDesktop'
          bodyChildrenProps={{
            padding: '0',
            maxHeight: '100%',
            overflow: 'auto'
          }}
          bodyCustomClass={styles.container_body}
          footerChildrenProps={{
            default: true,
            boxShadow: 'initial',
            borderEdge: 'all',
            borderRadius: '0',
            buttonDefaultProps: {
              isLoading: isLoadingPayment,
              onClick: handleContinueCustom,
              status: isMissingValuesForm ? 'disabled' : 'initial',
              label: 'Continuar',
              topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />
            }
          }}
          hasfooter={true}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerCustomClass={styles.container_header}
          headerTitle={TITLE_MODAL}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxHeight: HEIGHT_MODAL,
            minHeight: HEIGHT_MODAL,
            isOpen: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <ModalUnipayContent {...modalContentProps} />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='ModalUnipayMobile'
          body={<ModalUnipayContent {...modalContentProps} />}
          bodyContainerProps={{
            customClassName: styles['modalUnipayMobile__body']
          }}
          buttonConfigsProps={{
            isLoading: isLoadingPayment,
            onClick: handleContinueCustom,
            label: 'Continuar',
            topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />
          }}
          dragProps={{
            draggButton: false
          }}
          footerContainerProps={{
            boxShadow: 'initial',
            padding: '16px'
          }}
          hasFooter={true}
          headerProps={{
            customClassName: styles.container_header,
            padding: '3px 16px'
          }}
          headerTitle={TITLE_MODAL}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={13}
          isEnableButton={!isMissingValuesForm}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
