import Image from 'next/image'
import { Column, Picture, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

export interface IBodyModalProps {
  products: {
    id?: string
    name?: string
    image: string
  }[]
}

export const BodyModal = ({ products }: IBodyModalProps) => {
  return (
    <Column
      alignItems='center'
      customHeight='100%'
      gap={12}
      overflow='hidden'
    >
      <Row alignItems='center'>
        <Text
          display='ruby'
          fontSize='lg'
          fontWeight='regular'
          type='span'
        >
          ¡Lo sentimos! Algunos de tus productos no están disponibles &nbsp;
          <Text
            fontSize='lg'
            fontWeight='semibold'
            type='span'
          >
            al pagar con esta Giftcard.
          </Text>
        </Text>
      </Row>
      <Column
        alignItems='center'
        flexGrow={1}
        gap={10}
        overflow='auto'
      >
        {isValidArrayWithData(products) &&
          products.map((product) => {
            return (
              <Row
                alignItems='center'
                customHeight='67px'
                customWidth='100%'
                gap={10}
                key={product.id}
                maxHeight='67px'
                padding='0 16px'
              >
                <Column
                  customHeight='50px'
                  customWidth='20%'
                  maxWidth='50px'
                >
                  <Picture
                    alt={product.name}
                    height='100%'
                    nextImage={Image}
                    objectFit='cover'
                    src={product.image}
                    width='100%'
                  />
                </Column>
                <Column
                  gap={4}
                  justifyContent='center'
                  width='80%'
                >
                  <Text
                    fontSize='md'
                    textOverflow='ellipsis'
                  >
                    {product.name}
                  </Text>
                </Column>
              </Row>
            )
          })}
      </Column>
    </Column>
  )
}
