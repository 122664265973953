import Image from 'next/image'
import {
  Column,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface IBodyModalProps {
  availableAmount: string
}

export const BodyModal = ({ availableAmount }: IBodyModalProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  return (
    <Column
      alignItems='center'
      customHeight='100%'
      overflow='hidden'
    >
      {!isMobile && <Spacer.Horizontal customSize={52} />}
      <Picture
        alt='Cupo máximo excedido'
        height={isMobile ? '135px' : '140px'}
        nextImage={Image}
        src='/images/warning-img.png'
        width={isMobile ? '135px' : '140px'}
      />
      {!isMobile && <Spacer.Horizontal customSize={28} />}
      <Row
        alignItems='center'
        justifyContent='center'
        padding='4px 12px'
      >
        <Text
          fontSize='xl'
          fontWeight='semibold'
          type='span'
        >
          Cupo máximo excedido
        </Text>
      </Row>
      <Row
        alignItems='center'
        justifyContent='center'
        padding={isMobile ? '8px 32px' : '8px 40px'}
      >
        <Text
          display='ruby'
          fontSize='base'
          fontWeight='regular'
          textAlign='center'
          type='p'
        >
          Por motivos de seguridad, el &nbsp;
          <Text
            fontWeight='semibold'
            type='span'
          >
            cupo disponible
          </Text>
          &nbsp; de tu tarjeta Unipay es &nbsp;
          <Text
            fontWeight='semibold'
            type='span'
          >
            {availableAmount}.
          </Text>
        </Text>
      </Row>
    </Column>
  )
}
