import {
  Column,
  Icon,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getLastFourNumbersOfCard } from 'shared/helpers'
import { KIND_OF_GIFTCARD } from 'shared/constants'
import {
  AxiosResponse,
  isValidArrayWithData,
  TPostTransactionResponse,
  trigger,
  useCheckoutV2,
  useMobile,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import type { IGiftCard } from '..'

interface GifcardSelectedProps {
  giftcardData: IGiftCard
  handleToggleGiftCardModal: () => void
}

export const GiftcardSelected = ({
  giftcardData,
  handleToggleGiftCardModal
}: GifcardSelectedProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const queryClient = useQueryClient()
  const { data: checkout } = useCheckoutV2()

  const totalAmount = checkout?.value
  const isMobile = innerWidth < 1280
  const transactionResponse = queryClient.getMutationCache().find({
    mutationKey: 'transaction'
  })?.state.data as AxiosResponse<TPostTransactionResponse>

  const productsNotAllowed = isValidArrayWithData(
    transactionResponse?.data?.detail?.conflicts
  )
  const isGcMormon =
    giftcardData?.giftcardType?.toUpperCase() === KIND_OF_GIFTCARD.MORMON
  const hasEnoughAmount = giftcardData?.hasEnoughAmount
  const giftCardImage = isGcMormon
    ? '/images/giftcards/gc_mormones_miniature.png'
    : '/images/giftcard.png'
  const justPayOnce = hasEnoughAmount && isGcMormon && !productsNotAllowed
  const hasProductsNotAllowed = hasEnoughAmount && productsNotAllowed

  const handleToggleProductsNotAllowedModal = () => {
    trigger({
      eventType: 'toggleProductsNotAllowGiftcard',
      data: {
        show: true
      }
    })
  }

  return (
    <Column
      border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
      borderRadius={getGlobalStyle('--border-radius-md')}
      gap={isMobile ? 12 : 16}
      id={getBemId('giftcard-selected', 'container')}
      padding='16px'
    >
      <Column gap={8}>
        <Row justifyContent='between'>
          <Row maxWidth='max-content'>
            <Text
              fontSize='md'
              fontWeight='medium'
              id={getBemId('giftcard-title', 'container')}
            >
              Mi Giftcard
            </Text>
          </Row>
          <Text
            clickable='pointer'
            customColor={getGlobalStyle('--color-primary-light-red')}
            fontSize='md'
            fontWeight='medium'
            id={getBemId('giftcard', 'cambiar', 'buttonDesktop')}
            lineHeight='17px'
            onClick={handleToggleGiftCardModal}
          >
            Cambiar
          </Text>
        </Row>
        <Row
          alignItems='center'
          customHeight={isMobile ? '37px' : '42px'}
          id={getBemId('giftcard-info', 'container')}
          justifyContent='between'
        >
          <Row
            alignItems='center'
            gap={12}
            maxWidth='max-content'
          >
            <Picture
              hasURL
              height={isMobile ? '30px' : '43px'}
              id={getBemId('giftcard', 'image')}
              src={giftCardImage}
              width={isMobile ? '30px' : '43px'}
            />
            <Column
              gap={4}
              id={getBemId('giftcard-info', 'detail')}
              maxWidth='max-content'
            >
              <Text
                fontSize='md'
                fontWeight={isMobile ? 'medium' : 'semibold'}
                id={getBemId('giftcard', 'number')}
              >
                ****{getLastFourNumbersOfCard(giftcardData?.cardNumber)}
              </Text>
              <Text
                color='grayNeutral'
                fontSize='sm'
                id={getBemId('giftcard', 'balance')}
              >
                Saldo: {giftcardData?.balance}
              </Text>
            </Column>
          </Row>
          <Row maxWidth='max-content'>
            <Text
              fontSize='base'
              fontWeight='medium'
              id={getBemId('giftcard', 'buy', 'amount')}
            >
              {totalAmount}
            </Text>
          </Row>
        </Row>
      </Column>
      {!hasEnoughAmount && (
        <Row
          alignItems='center'
          gap={5}
          id={getBemId('giftcard-info', 'container')}
          padding={isMobile ? '2px 0' : '0'}
        >
          <Row
            customHeight={isMobile ? '20px' : '24px'}
            customWidth={isMobile ? '20px' : '24px'}
            id={getBemId('giftcard-info', 'icon')}
            maxWidth='max-content'
          >
            <Icon
              color={getGlobalStyle('--color-feedback-info')}
              customSize={isMobile ? 20 : 24}
              name='InfoFillThin'
            />
          </Row>
          <Text
            fontSize={isMobile ? 'sm' : 'md'}
            id={getBemId('giftcard-info', 'message')}
          >
            El saldo no es suficiente, por favor escoge otra Giftcard o medio de
            pago.
          </Text>
        </Row>
      )}
      {justPayOnce && (
        <Row
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-secondary-blue-bg')}
          borderRadius='8px'
          customHeight={isMobile ? '38px' : '28px'}
          gap={5}
          id={getBemId('giftcard-mormones-info', 'container')}
          padding='4px'
        >
          <Icon
            color={getGlobalStyle('--color-feedback-info')}
            customSize={24}
            name='InfoFillThin'
          />
          <Text
            display='ruby'
            fontSize='sm'
            id={getBemId('giftcard-mormones-info', 'message')}
          >
            Recuerda que puedes pagar con esta Giftcard &nbsp;
            <Text
              fontSize='sm'
              fontWeight='medium'
              id={getBemId('giftcard-mormones-info', 'message')}
              type='span'
            >
              sólo una vez.
            </Text>
          </Text>
        </Row>
      )}
      {hasProductsNotAllowed && (
        <Row
          alignItems='center'
          borderRadius='8px'
          customHeight='28px'
          gap={5}
          id={getBemId('giftcard-mormones-info', 'container')}
          padding='4px'
        >
          <Icon
            color={getGlobalStyle('--color-feedback-info')}
            customSize={24}
            name='InfoFillThin'
          />

          <Text
            clickable='pointer'
            display='ruby'
            fontSize='sm'
            id={getBemId('giftcard-mormones-info', 'message')}
            onClick={handleToggleProductsNotAllowedModal}
          >
            Algunos de tus productos no están permitidos con esta Giftcard.
            &nbsp;
            <Text
              clickable='pointer'
              fontSize='sm'
              fontWeight='semibold'
              id={getBemId('giftcard-mormones-info', 'message')}
              type='span'
            >
              Revísalos aquí
            </Text>
          </Text>
        </Row>
      )}
    </Column>
  )
}
