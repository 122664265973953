import { forwardRef, useState } from 'react'
import classNames from 'classnames'
import { Column, Container } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useDelay } from '@smu-chile/pkg-unimarc-hooks'
import { ToastError } from './ToastError'
import { ToastSuccess } from './ToastSuccess'
import { ToastInfo } from './ToastInfo'
import { TPortalProps } from './helpers/interfaces'
import styles from './styles.module.css'
import { ToastIndexingMembership } from './ToastIndexingMembership'

export type TToastMessage = 'success' | 'error' | 'info'
export interface IToastWrapperProps {
  type?: TToastMessage | string
  codeError?: string
  isIndexingMessage?: boolean
  success?: boolean
  toastMessage?: string
  portalProps?: Partial<TPortalProps>
  handleToastVisibility: () => void
}

const ToastWrapper = forwardRef<HTMLElement, IToastWrapperProps>(function Toast(
  {
    codeError,
    isIndexingMessage,
    success,
    toastMessage,
    type,
    portalProps,
    handleToastVisibility
  },
  ref
) {
  const [startAnimation, setStartAnimation] = useState(false)

  const handleStartAnimation = () => {
    setStartAnimation(true)
  }

  useDelay({
    setClassAnimation: handleStartAnimation,
    actionCallback: handleToastVisibility
  })

  return (
    <Container
      className={classNames({
        [styles['toastWrapper__animate']]: startAnimation
      })}
      id={getBemId('toastWrapper', 'container')}
      ref={ref}
    >
      <BigScreen>
        <>
          {isIndexingMessage && <ToastIndexingMembership />}
          {type === 'info' && (
            <ToastInfo
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
          {success && !type && (
            <ToastSuccess
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
          {codeError?.length > 0 && (
            <ToastError
              codeError={codeError}
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
        </>
      </BigScreen>
      <SmallScreen>
        <Column
          margin='5px 0 0 0'
          position={portalProps?.position ?? 'fixed'}
          zIndex={getGlobalStyle('--z-index-99999')}
        >
          {type === 'info' && (
            <ToastInfo
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
          {success && !type && (
            <ToastSuccess
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
          {codeError?.length > 0 && (
            <ToastError
              codeError={codeError}
              handleOnClosed={handleToastVisibility}
              portalProps={portalProps}
              toastMessage={toastMessage}
            />
          )}
        </Column>
      </SmallScreen>
    </Container>
  )
})

export { ToastWrapper }
