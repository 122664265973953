import { useEffect, useState } from 'react'
import {
  isValidArrayWithData,
  patchGiftcards,
  replaceStrings,
  trigger,
  useCards,
  useCheckoutV2,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { useDataLayers, useSetLastCard } from 'shared/hooks'
import {
  Column,
  ModalBottomSheet,
  Picture,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IGiftCard } from '../GiftcardComponent'

interface ModalDeleteGiftCardProps {
  deleteGCModalImage: string
  giftCardToDelete: IGiftCard
  isOpen: boolean
  onClose: () => void
}

export const ModalDeleteGiftCard = ({
  deleteGCModalImage,
  giftCardToDelete,
  isOpen,
  onClose
}: ModalDeleteGiftCardProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const { data: checkout, temporalMutate } = useCheckoutV2()
  const { refetch: refetchCards } = useCards({ version: 2 })
  const { handleSetLastCard, isLoadingSetLastCard } = useSetLastCard()
  const { getErrorEvent } = useDataLayers()

  const handleClick = async () => {
    setIsLoadingBtn(true)
    const response = await patchGiftcards({
      data: [
        {
          cardNumber: giftCardToDelete?.cardNumber.replace(/-/g, ''),
          active: false,
          document: checkout?.profile?.document
        }
      ]
    })
    if (response?.status === 200) {
      const dataCards = await refetchCards()
      if (isValidArrayWithData(dataCards?.data?.nominatives)) {
        const nominativeCards = dataCards?.data?.nominatives
        const nominativeGiftcardSorted = nominativeCards
          .slice()
          .sort((a, b) => {
            return replaceStrings(b.balance) - replaceStrings(a.balance)
          })

        const responseNominative = await patchGiftcards({
          data: [
            {
              cardNumber: nominativeGiftcardSorted[0]?.card,
              pin: '',
              active: true,
              document: checkout?.profile?.document
            }
          ]
        })
        if (responseNominative?.status === 200) {
          temporalMutate({
            paymentInfo: {
              ...checkout?.paymentInfo,
              selectedPayments: null,
              giftCards: responseNominative?.data?.giftcards ?? []
            },
            totals: responseNominative?.data?.totals,
            value: responseNominative?.data?.value
          })
        }
      } else {
        temporalMutate({
          paymentInfo: {
            ...checkout?.paymentInfo,
            giftCards: response?.data?.giftcards ?? []
          },
          totals: response?.data?.totals,
          value: response?.data?.value
        })
        await handleSetLastCard()
      }
      trigger({
        eventType: 'checkoutToast',
        data: {
          show: true,
          success: true,
          toastMessage: 'Tu Giftcard se ha eliminado con éxito.'
        }
      })
    } else {
      const errorCode = getErrorEvent({
        endpoint: 'giftcards',
        error: response?.data?.error
      })
      trigger({
        eventType: 'checkoutToast',
        data: {
          codeError:
            errorCode?.show_code ||
            response?.data?.code ||
            response?.status?.toString(),
          show: true,
          toastMessage:
            'No fue posible eliminar tu Giftcard. Intenta nuevamente'
        }
      })
    }
    setIsLoadingBtn(false)
    onClose()
  }

  useEffect(() => {
    if (isLoadingSetLastCard && !isLoadingBtn) {
      setIsLoadingBtn(true)
    }
  }, [isLoadingSetLastCard])

  return (
    <ModalBottomSheet
      customHeightContent='100%'
      id={getBemId('deleteGCModal', 'container')}
      isLoadingBtn={isLoadingBtn}
      isMobile={isMobile}
      isOpen={isOpen}
      isWindowBlocked={true}
      maxWidthModal='440px'
      minHeightModal='435px'
      minHeightModalMobile='550px'
      onClose={onClose}
      primaryBtnLabel='Eliminar mi Giftcard'
      primaryCTA={handleClick}
      secondaryBtnLabel='En otro momento'
      secondaryCTA={onClose}
    >
      <Column
        alignItems='center'
        height='100%'
        justifyContent='center'
      >
        <Picture
          height={isMobile ? '156px' : '134px'}
          id={getBemId('deleteGCModal', 'image')}
          src={deleteGCModalImage}
          width={isMobile ? '265px' : '218px'}
        />
        <SmallScreen>
          <Spacer.Horizontal size={16} />
        </SmallScreen>
        <Spacer.Horizontal size={4} />
        <Text
          fontSize='xl'
          fontWeight='semibold'
          id={getBemId('deleteGCModal', 'title')}
          textAlign='center'
        >
          ¿Quieres eliminar tu Giftcard?
        </Text>
        <Spacer.Horizontal size={isMobile ? 16 : 12} />
        <Text
          id={getBemId('deleteGCModal', 'subtitle')}
          lineHeight='normal'
          textAlign='center'
        >
          Si la eliminas tendrás que agregarla nuevamente para usarla.
        </Text>
      </Column>
    </ModalBottomSheet>
  )
}
