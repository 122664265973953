import { Tooltip } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ToastBodyIndexing } from './ToastBodyIndexing'

export const ToastIndexingMembership = () => {
  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-secondary-blue-bg')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      boxShadow='none'
      closeColor={getGlobalStyle('--color-neutral-black')}
      closeIcon={false}
      display='flex'
      height='60px'
      maxWidth='375px'
      padding='12px 16px'
      position='sticky'
      positionContainer='sticky'
      textComponent={<ToastBodyIndexing />}
      type='bottom'
      width='375px'
    />
  )
}
