import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger, useQueryClient } from '@smu-chile/pkg-unimarc-hooks'
import {
  BodyModal,
  IBodyModalProps,
  FooterModal,
  IFooterModalProps
} from './components'
import styles from '../styles.module.css'

export const ModalUnipayValueGetOver = () => {
  const queryClient = useQueryClient()
  const postUnipayValidateAccount = queryClient
    .getMutationCache()
    .findAll({
      mutationKey: 'postUnipayValidateAccount'
    })
    ?.pop()?.state.data as { data: { detail: { availableAmount: string } } }

  const handleClose = () => {
    trigger({
      eventType: 'toggleModalUnipayValueGetOver',
      data: { show: false }
    })
  }

  const handleChangePaymentMethod = () => {
    handleClose()
    trigger({
      eventType: 'toggleModalUnipay',
      data: { show: false }
    })
    trigger({
      eventType: 'toggleShowPaymentMethods',
      data: {
        show: true
      }
    })
  }

  const handleMoveToCart = () => {
    window.location.href = process.env.NEXT_PUBLIC_CARTURL
  }

  const bodyModalProps: IBodyModalProps = {
    availableAmount: postUnipayValidateAccount?.data?.detail?.availableAmount
  }

  const footerModalProps: IFooterModalProps = {
    onChangePaymentMethod: handleChangePaymentMethod,
    onMoveToCart: handleMoveToCart
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modal-unipay-value-get-over'
          bodyChildrenProps={{
            overflow: 'hidden',
            padding: '0px',
            margin: '0px'
          }}
          footerChildrenProps={{
            boxShadow: 'none',
            default: false,
            children: <FooterModal {...footerModalProps} />,
            padding: '16px'
          }}
          hasHeader={false}
          headerCloseIcon={{ default: false }}
          headerCustomClass={styles.container_header}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxWidth: '440px',
            minHeight: '440px',
            isOpen: true
          }}
        >
          <BodyModal {...bodyModalProps} />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='modal-unipay-value-get-over'
          body={<BodyModal {...bodyModalProps} />}
          bodyContainerProps={{
            padding: '0px',
            maxHeight: '400px'
          }}
          customFooter={<FooterModal {...footerModalProps} />}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerProps={{
            padding: '8px 16px',
            style: {
              display: 'none'
            }
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          isHiddenHeaderCloseButton={true}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            maxHeightFullScreen: '400px',
            minHeightFullScreen: '400px'
          }}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
