import { useState } from 'react'

import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  AxiosResponse,
  TPostTransactionResponse,
  useQueryClient,
  patchOrderFormNewBFF,
  replaceStrings,
  trigger,
  Cookies
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BodyModal,
  IBodyModalProps,
  FooterModal,
  IFooterModalProps
} from './components'
import { useDataLayers } from 'shared/hooks'
import type {
  ICart,
  PatchCartRequest
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { IErrorNewBFF } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IErrorNewBFF'
import { COOKIES_KEYS } from 'shared/constants'
import styles from '../styles.module.css'

const TITLE = 'Productos con restricción'

export interface IModalProductsNotAllowed {
  handleToggle: () => void
}

export const ModalProductsNotAllowed = ({
  handleToggle
}: IModalProductsNotAllowed) => {
  const [isLoadingRemoveProducts, setIsLoadingRemoveProducts] = useState(false)
  const queryClient = useQueryClient()
  const { getErrorEvent } = useDataLayers()

  const transactionResponse = queryClient.getMutationCache().find({
    mutationKey: 'transaction'
  })?.state.data as AxiosResponse<TPostTransactionResponse>
  const productsNotAllowed = transactionResponse?.data?.detail?.conflicts

  const handleClose = () => {
    handleToggle()
  }

  const handleChangePaymentMethod = () => {
    handleToggle()
    trigger({
      eventType: 'toggleShowPaymentMethods',
      data: {
        show: true
      }
    })
  }

  const handleRemoveProducts = async () => {
    try {
      setIsLoadingRemoveProducts(true)
      // remove products not allowed
      const response = (await patchOrderFormNewBFF({
        items: productsNotAllowed.map((product) => {
          return {
            ...product,
            quantity: 0
          }
        }) as PatchCartRequest['items']
      })) as ICart & IErrorNewBFF

      if (response?.status !== 200) {
        // if the response is not 200, show a toast with the error
        setIsLoadingRemoveProducts(false)
        const errorCode = getErrorEvent({
          endpoint: 'cards',
          error: response?.error
        })
        trigger({
          eventType: 'checkoutToast',
          data: {
            codeError:
              errorCode?.show_code ||
              response?.code ||
              response?.status?.toString(),
            show: true,
            toastMessage:
              'No fue posible eliminar tus productos. Intenta nuevamente'
          }
        })
        handleClose()
        return
      }
      // clean mutation cache
      queryClient.getMutationCache().clear()

      // check if the new subtotal is greater than the minimum purchase
      const hasEnoughAmount =
        replaceStrings(response?.subtotal) >=
        replaceStrings(response?.minimumPurchase)

      const dataToast = {
        show: true,
        success: true,
        toastMessage: 'Los productos se eliminaron con éxito'
      }

      if (hasEnoughAmount) {
        trigger({
          eventType: 'checkoutToast',
          data: dataToast
        })
        handleClose()
      } else {
        // redirect to cart
        Cookies.set(COOKIES_KEYS.SHOW_TOAST, JSON.stringify(dataToast), {
          domain: process.env.NEXT_PUBLIC_COOKIESDOMAIN
        })
        window.location.href = process.env.NEXT_PUBLIC_CARTURL
      }
      setIsLoadingRemoveProducts(false)
    } catch (error) {
      const response = (error?.response as AxiosResponse) ?? {
        data: {
          error: 'Error al eliminar productos'
        },
        status: 500
      }
      setIsLoadingRemoveProducts(false)
      const errorCode = getErrorEvent({
        endpoint: 'cards',
        error: response?.data?.error
      })
      trigger({
        eventType: 'checkoutToast',
        data: {
          codeError:
            errorCode?.show_code ||
            response?.data?.code ||
            response?.status?.toString(),
          show: true,
          toastMessage:
            'No fue posible eliminar tus productos. Intenta nuevamente'
        }
      })
      handleClose()
    }
  }

  const bodyModalProps: IBodyModalProps = {
    products: productsNotAllowed as IBodyModalProps['products']
  }

  const footerModalProps: IFooterModalProps = {
    isLoadingRemoveProducts,
    onChangePaymentMethod: handleChangePaymentMethod,
    onRemoveProducts: handleRemoveProducts
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modal-products-not-allowed'
          bodyChildrenProps={{
            customHeight: '368px',
            overflow: 'hidden',
            padding: '20px 28px 20px 28px',
            margin: '0px'
          }}
          footerChildrenProps={{
            default: false,
            children: <FooterModal {...footerModalProps} />
          }}
          headerCloseIcon={{
            default: true,
            customSize: 20
          }}
          headerCustomClass={styles.container_header}
          headerTitle={TITLE}
          headerTitleProps={{
            fontWeight: 'semibold',
            fontSize: 'xl'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxWidth: '440px',
            minHeight: '564px',
            isOpen: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
        >
          <BodyModal {...bodyModalProps} />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='modal-products-not-allowed'
          body={<BodyModal {...bodyModalProps} />}
          bodyContainerProps={{
            padding: '0px 16px 0px 16px',
            maxHeight: 'calc(100vh - 280px)'
          }}
          customFooter={<FooterModal {...footerModalProps} />}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerCloseIconProps={{
            customSize: 20
          }}
          headerProps={{
            padding: '8px 16px'
          }}
          headerTitle={TITLE}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleClose}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
